<template>
    <PrivacyPolicy/>

</template>

<script>

import PrivacyPolicy from '../components/PrivacyPolicy.vue'


export default {
  // components: { HeroSection, FeaturedSection, ReportManagmentSection, TprsSection, 
  // UspsSection, ClientSection, TestimonialSection, }
  components: { PrivacyPolicy }
}
</script>

<style>

</style>
