<template>
    <HeroSection />
    <FeaturedSection />
    <TprsSection/>
     <UspsSection />
    <ReportManagmentSection />
    <ClientSection />
    <!-- <TestimonialSection /> -->
</template>

<script>

import HeroSection from '../components/HeroSection.vue'
import FeaturedSection from '../components/FeaturedSection.vue'
import ReportManagmentSection from '../components/ReportManagmentSection.vue'
import TprsSection from '../components/TprsSection.vue'
import UspsSection from '../components/UspsSection.vue'
import ClientSection from '../components/ClientSection.vue'
// import TestimonialSection from '../components/TestimonialSection.vue'

export default {
  // components: { HeroSection, FeaturedSection, ReportManagmentSection, TprsSection, 
  // UspsSection, ClientSection, TestimonialSection, }
  components: { HeroSection, FeaturedSection, ReportManagmentSection, TprsSection, 
  UspsSection, ClientSection, }
}
</script>

<style>

</style>
