import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas} from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import './assets/sass/screen.scss';
import VueScrollTo from 'vue-scrollto'
import router from './router'


library.add(fas, fab);
createApp(App).use(router).use(VueScrollTo)
.component('fa', FontAwesomeIcon)
.mount('#app')
