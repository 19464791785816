<template>
  <section id="tprs-sec" class="tprs-sec">
    <div class="container">
      
      <h2 class="text-center mb-3 text-primary fw-bold">
        Different Types of TPRs / Initiatives
      </h2>
      <p class="mb-5 text-center px-lg-7">
        Our system is now capable to facilitate the following variety of trade promotions/TPRs/Initiatives.
      </p>
      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-center">
          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active px-lg-5" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Quantity Discounts</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link px-lg-5" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Prompt Payment Discount</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class="nav-link px-lg-5" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Non-Returnable Allownace</button>
  </li>
</ul>
</div>
<div class="tab-content mt-4" id="pills-tabContent">
  <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
    <div class="row d-flex justify-content-center">
      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-4 pt-0">
                <div class="img-wrap mb-4 mx-auto">
                  <h1 class="fw-bold mb-0">1</h1>
                </div>
                <h5 class="card-title mb-3 text-white">
                 Basket TPRs
                </h5>
                <p class="text-white">Discont on collective purchase of certain products in a single invoice</p>
               
              </div>
            </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-4 pt-0">
                <div class="img-wrap mb-4 mx-auto">
                  <h1 class="fw-bold mb-0">2</h1>
                </div>
                <h5 class="card-title mb-3 text-white">
                 Multiple TPRs</h5>
                <p class="text-white">Developing multiple TPRs/Discont on SKU/Brand level</p>
               
              </div>
            </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-4 pt-0">
                <div class="img-wrap mb-4 mx-auto">
                  <h1 class="fw-bold mb-0">3</h1>
                </div>
                <h5 class="card-title mb-3 text-white">
                 Bulk Order TPRs</h5>
                <p class="text-white">Discount on certain X % purchase vs. LY, P3M, P6M, P1M etc, on brand, line up SKU Level</p>
               
              </div>
            </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-4 pt-0">
                <div class="img-wrap mb-4 mx-auto">
                  <h1 class="fw-bold mb-0">4</h1>
                </div>
                <h5 class="card-title mb-3 text-white">
                 In Active SKU TPRs</h5>
                <p class="text-white">If any SKU/Line up is inactive in a certain time period P1M, P3M etc, then X% discount to be applicable on its prucahse</p>
               
              </div>
            </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-4 pt-0">
                <div class="img-wrap mb-4 mx-auto">
                  <h1 class="fw-bold mb-0">5</h1>
                </div>
                <h5 class="card-title mb-3 text-white">
                 Launch TPRs</h5>
                <p class="text-white">TPR on first order of a newly lauched SKU</p>
               
              </div>
            </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-4 pt-0">
                <div class="img-wrap mb-4 mx-auto">
                  <h1 class="fw-bold mb-0">6</h1>
                </div>
                <h5 class="card-title mb-3 text-white">
                Geographical TPRs</h5>
                <p class="text-white">TPR on first order of a newly lauTPRs on a certain brand. Lineup , SKU only in a speciafic city, territory or geography</p>
               
              </div>
            </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-4 pt-0">
                <div class="img-wrap mb-4 mx-auto">
                  <h1 class="fw-bold mb-0">7</h1>
                </div>
                <h5 class="card-title mb-3 text-white">
                Budget Bond TPRs</h5>
                <p class="text-white">System should have the capability to deploy budget bound TPRs</p>
               
              </div>
            </div>
      </div>
    </div>
  </div>
  <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
    <div class="row d-flex justify-content-center">
      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-4 pt-0">
                <div class="img-wrap mb-4 mx-auto">
                  <h1 class="fw-bold mb-0">1</h1>
                </div>
                <h5 class="card-title mb-3 text-white">
                 Basket TPRs
                </h5>
                <p class="text-white">Discont on collective purchase of certain products in a single invoice</p>
               
              </div>
            </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-4 pt-0">
                <div class="img-wrap mb-4 mx-auto">
                  <h1 class="fw-bold mb-0">2</h1>
                </div>
                <h5 class="card-title mb-3 text-white">
                 Multiple TPRs</h5>
                <p class="text-white">Developing multiple TPRs/Discont on SKU/Brand level</p>
               
              </div>
            </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-4 pt-0">
                <div class="img-wrap mb-4 mx-auto">
                  <h1 class="fw-bold mb-0">3</h1>
                </div>
                <h5 class="card-title mb-3 text-white">
                 Bulk Order TPRs</h5>
                <p class="text-white">Discount on certain X % purchase vs. LY, P3M, P6M, P1M etc, on brand, line up SKU Level</p>
               
              </div>
            </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-4 pt-0">
                <div class="img-wrap mb-4 mx-auto">
                  <h1 class="fw-bold mb-0">4</h1>
                </div>
                <h5 class="card-title mb-3 text-white">
                 In Active SKU TPRs</h5>
                <p class="text-white">If any SKU/Line up is inactive in a certain time period P1M, P3M etc, then X% discount to be applicable on its prucahse</p>
               
              </div>
            </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-4 pt-0">
                <div class="img-wrap mb-4 mx-auto">
                  <h1 class="fw-bold mb-0">5</h1>
                </div>
                <h5 class="card-title mb-3 text-white">
                 Launch TPRs</h5>
                <p class="text-white">TPR on first order of a newly lauched SKU</p>
               
              </div>
            </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-4 pt-0">
                <div class="img-wrap mb-4 mx-auto">
                  <h1 class="fw-bold mb-0">6</h1>
                </div>
                <h5 class="card-title mb-3 text-white">
                Geographical TPRs</h5>
                <p class="text-white">TPR on first order of a newly lauTPRs on a certain brand. Lineup , SKU only in a speciafic city, territory or geography</p>
               
              </div>
            </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-4 pt-0">
                <div class="img-wrap mb-4 mx-auto">
                  <h1 class="fw-bold mb-0">7</h1>
                </div>
                <h5 class="card-title mb-3 text-white">
                Budget Bond TPRs</h5>
                <p class="text-white">System should have the capability to deploy budget bound TPRs</p>
               
              </div>
            </div>
      </div>
    </div>
  </div>
  <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
    <div class="row d-flex justify-content-center">
      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-4 pt-0">
                <div class="img-wrap mb-4 mx-auto">
                  <h1 class="fw-bold mb-0">1</h1>
                </div>
                <h5 class="card-title mb-3 text-white">
                 Basket TPRs
                </h5>
                <p class="text-white">Discont on collective purchase of certain products in a single invoice</p>
               
              </div>
            </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-4 pt-0">
                <div class="img-wrap mb-4 mx-auto">
                  <h1 class="fw-bold mb-0">2</h1>
                </div>
                <h5 class="card-title mb-3 text-white">
                 Multiple TPRs</h5>
                <p class="text-white">Developing multiple TPRs/Discont on SKU/Brand level</p>
               
              </div>
            </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-4 pt-0">
                <div class="img-wrap mb-4 mx-auto">
                  <h1 class="fw-bold mb-0">3</h1>
                </div>
                <h5 class="card-title mb-3 text-white">
                 Bulk Order TPRs</h5>
                <p class="text-white">Discount on certain X % purchase vs. LY, P3M, P6M, P1M etc, on brand, line up SKU Level</p>
               
              </div>
            </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-4 pt-0">
                <div class="img-wrap mb-4 mx-auto">
                  <h1 class="fw-bold mb-0">4</h1>
                </div>
                <h5 class="card-title mb-3 text-white">
                 In Active SKU TPRs</h5>
                <p class="text-white">If any SKU/Line up is inactive in a certain time period P1M, P3M etc, then X% discount to be applicable on its prucahse</p>
               
              </div>
            </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-4 pt-0">
                <div class="img-wrap mb-4 mx-auto">
                  <h1 class="fw-bold mb-0">5</h1>
                </div>
                <h5 class="card-title mb-3 text-white">
                 Launch TPRs</h5>
                <p class="text-white">TPR on first order of a newly lauched SKU</p>
               
              </div>
            </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-4 pt-0">
                <div class="img-wrap mb-4 mx-auto">
                  <h1 class="fw-bold mb-0">6</h1>
                </div>
                <h5 class="card-title mb-3 text-white">
                Geographical TPRs</h5>
                <p class="text-white">TPR on first order of a newly lauTPRs on a certain brand. Lineup , SKU only in a speciafic city, territory or geography</p>
               
              </div>
            </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-6">
        <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-4 pt-0">
                <div class="img-wrap mb-4 mx-auto">
                  <h1 class="fw-bold mb-0">7</h1>
                </div>
                <h5 class="card-title mb-3 text-white">
                Budget Bond TPRs</h5>
                <p class="text-white">System should have the capability to deploy budget bound TPRs</p>
               
              </div>
            </div>
      </div>
    </div>
  </div>
</div>
        </div>
      </div>
      
    </div>
  </section>
</template>
<style>
</style>