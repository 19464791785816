<template>
    <footer class="footer">
        <div class="container">
          <div class="img-wrap text-center footer-logo mb-4">
          <router-link to="/"> <img class="img-fluid" :src="require('../assets/images/evendy-logo-dark.png')" alt="" srcset=""> </router-link>
          </div>
          <ul class="list-unstyled d-flex justify-content-center mb-4">
            <li class="mx-3"><a class="text-body" href="#" v-scroll-to="'#hero-sec'">Home</a></li>
            <li class="mx-3"><a class="text-body" href="#" v-scroll-to="'#feature-sec'">Features</a></li>
            <li class="mx-3"><a class="text-body" href="#" v-scroll-to="'#report-mngmnt-sec'">Reports</a></li>
            <li class="mx-3"> <router-link class="text-body" to="/contact">Contact Us </router-link></li>
            <li class="mx-3"> <router-link class="text-body" to="/privacy-policy">Privacy Policy </router-link></li>
          </ul>
          <div class="social-icons d-flex justify-content-center mb-3">
            <a class="rounded-circle bg-primary text-white mx-2" href="https://www.facebook.com/Vendipk-107412725011866"><fa :icon="['fab', 'facebook-f']"/></a>
            <a class="rounded-circle bg-primary text-white mx-2" href="https://www.linkedin.com/company/vendipk"><fa :icon="['fab', 'linkedin-in']"/></a>
            <a class="rounded-circle bg-primary text-white mx-2" href="mailto:info@vendi.pk"><fa :icon="['fas', 'envelope']"/></a>
          </div>
          <p class="mb-0 text-center fs-14 opacity-5">©2021 All Copyright Reserved by <a class="text-info" href="https://vendi.pk/">Vendi</a></p>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>