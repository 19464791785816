<template>
    <section id="hero-sec" class="hero-sec">
        <div class="container">
            <div class="row flex-lg-row-reverse align-items-center">
                <div class="col-12">
                    <div class="main-head">
                        <h1 class="mb-3 text-center text-white fw-bold">Goodbye Spread Sheets- Welcome The Vendi
                        A Comprehensive Inventory, 
                    </h1>
                    <h3 class="mb-4 text-center text-white fw-bold">Order & Warehouse Management Solution</h3>
                    
                    </div>
                    <p class="mb-5 text-center text-white w-lg-90 mx-lg-auto px-md-4">Sell, service, market, and connect to grow your business every day.
                    Vendi helps you streamline and automate your distribution network, making the process more efficient and helps you in tracking the Goods Inventory and increase visibility over the complete Supply Chain cycle right from the stage of receiving Sales Order to delivering the ordered goods and Payment Receipts. 
  
                    </p>
                    <div class="mb-7 d-flex justify-content-center">
                        <button v-scroll-to="'#feature-sec'" type="button" class="btn shadow-lg rounded-pill border-3 border-white bg-white text-uppercase btn-lg text-primary px-5">Get Started</button>
                        <!-- <button type="button" class="btn shadow-lg rounded-pill border-3 border-white text-uppercase btn-lg ms-4 px-5 text-white">Live Demo</button> -->
                    </div>
                    


                     <carousel :settings="settings" :breakpoints="breakpoints">
                          <slide :key="slide">
                            <div class="carousel__item">
                    <div class="img-wrap w-75 mx-auto">
                        <img :src="require('../assets/images/dashboard-img2.png')" alt="hero-img">
                    </div>
                            </div>
                          </slide>
                        
                        <slide :key="slide">
                            <div class="carousel__item">
                    <div class="img-wrap w-75 mx-auto">
                        <img :src="require('../assets/images/slider-2.jpg')" alt="hero-img">
                    </div>
                            </div>
                          </slide>

                          <template #addons="{ slidesCount }">
                <navigation />
                <pagination v-if="slidesCount > 1"/>
                </template>
                     </carousel>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide,Pagination} from "vue3-carousel";

export default {
  name: "App",
  components: {
    Carousel,
    Slide,
    // Navigation,
Pagination
  },
  data() {
    return {
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        wrapAround: "true",
        // autoplay: 2500,
        // transition: 1000
      },
      breakpoints: {
        450: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        576: {
          itemsToShow: 1,
          snapAlign: "start",
        },
        992: {
          itemsToShow: 1,
          snapAlign: "start",
        },
      },
    };
  },
};
</script>


<style>

</style>