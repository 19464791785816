<template>
  <TopNavbar />
  <div id="main-wraper" class="main-wraper">
    <router-view> </router-view>
    <FooterSection />
  </div>
</template>

<script>
import TopNavbar from './components/TopNavbar.vue'
import FooterSection from './components/FooterSection.vue'
export default {
  components: {TopNavbar, FooterSection}
}
</script>

<style>

</style>
