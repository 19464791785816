<template>
  <section id="report-mngmnt-sec" class="report-mngmnt-sec wow animate__animated animate__fadeIn" data-wow-delay="0.3s">
        <div class="container">
            <div class="img-wrap title-logo mb-1">
                <img class="img-fluid" :src="require('../assets/images/evendy-logo-dark.png')" alt="" srcset="">
            </div>
            <h2 class="text-center mb-3 text-primary fw-bold">Report Management</h2>
            <p class="mb-5 text-center px-lg-7">Use predefined dashboards or build your own with the advanced reporting engine —Share filters with the team.
            </p>
            <div class="row flex-lg-row-reverse align-items-center">
                <div class="col-lg-5 mb-4 mb-lg-0 mx-auto ps-lg-5 text-center">
                    <img class="img-fluid" :src="require('../assets/images/report-mangment-img.png')" alt="" srcset="">
                </div>
                <div class="col-lg-7">
                    <ul class="list-unstyled d-flex flex-wrap justify-content-between mb-0">
                        <li><a href="#">Distributor Sales & Stock Report</a></li>
                        <li><a href="#">Item Price List</a></li>
                        <li><a href="#">Secondary Sale Report</a></li>
                        <li><a href="#">Master Outlet Report</a></li>
                        <li><a href="#">Distributor KPI Report</a></li>
                        <li><a href="#">Master Employee</a></li>
                        <li><a href="#">Master Closing Stock Report</a></li>
                        <li><a href="#">Primary Sale Report</a></li>
                        <li><a href="#">Target vs Achievement Secondary</a></li>
                        <li><a href="#">Master Distribution Details</a></li>
                        <li><a href="#">Zero Sale Report</a></li>
                        <li><a href="#">Month wise Secondary Sale</a></li>
                        <li><a href="#">Raw Sale Report</a></li>
                        <li><a href="#">Previous Day Activity</a></li>
                    </ul>
                    <h3 class="mt-4 mb-3 text-primary fw-bold">Advance Reports</h3>
                    <ul class="list-unstyled d-flex flex-wrap justify-content-between mb-0">
                        <li><a href="#">Sales hierarchy wise Reports</a></li>
                         <li><a href="#">Advance Target vs Achievement Reports</a></li>
                        <li><a href="#">SKU wise Sales Reports</a></li>
                       
                    </ul>
                    
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>