<template>
    <section id="feature-sec" class="feature-sec">
        <div class="container">
            <div class="img-wrap title-logo mb-1">
                <img class="img-fluid" :src="require('../assets/images/evendy-logo-dark.png')" alt="" srcset="">
            </div>
            <h2 class="text-center mb-3 text-primary fw-bold">Advanced features made simple.</h2>
            <p class="mb-5 text-center px-lg-7">Use cutting-edge automation and advanced routes to manage any warehouse.
            </p>
            <carousel :settings="settings" :breakpoints="breakpoints">
                <slide :key="slide">
                    <div class='carousel__item'>
                        <div class="card rounded-3 bg-primary-light border-0 h-100">
                            <div class="card-body text-center py-5">
                                <div class="img-wrap mb-4 mx-auto">
                                    <img class="img-fluid" :src="require('../assets/images/sale-managment-icon.png')" alt="" srcset="">
                                </div>
                                <h5 class="card-title fw-bold">Sales Order Management</h5>
                                <h6 style="font-weight:600">Automating Distribution, Sales Channel Management</h6>
                                <p class="card-text">Quickly create, update and manage all your online and offline orders to streamline equipment delivery operations across locations.
                                </p>
                            </div>
                        </div>
                    </div>
                </slide>
                <slide :key="slide">
                    <div class='carousel__item'>
                        <div class="card rounded-3 bg-secondary-light border-0 h-100">
                            <div class="card-body text-center py-5">
                                <div class="img-wrap mb-4 mx-auto">
                                    <img class="img-fluid" :src="require('../assets/images/account-managment-icon.png')" alt="" srcset="">
                                </div>
                                <h5 class="card-title fw-bold">Inventory Management</h5>
                                <h6 style="font-weight:600">Synchronize Primary & Secondary business tiers.</h6>
                                <p class="card-text">Do an inventory for a zone, a specific product, a lot, or a pallet/box; Vendi prepares cycle counts for you. Visibility of Primary & Secondary 
                                </p>
                            </div>
                        </div>
                    </div>
                </slide>
                <slide :key="slide">
                    <div class='carousel__item'>
                        <div class="card rounded-3 bg-pink-light border-0 h-100">
                            <div class="card-body text-center py-5">
                                <div class="img-wrap mb-4 mx-auto">
                                    <img class="img-fluid" :src="require('../assets/images/manage-inventory-icon.png')" alt="" srcset="">
                                </div>
                                <h5 class="card-title fw-bold">Account Management</h5>
                                
                                <p class="card-text">Create customized professional-grade invoices and auto-generate these invoices to track payments on monthly orders with ease.
                                </p>
                            </div>
                        </div>
                    </div>
                </slide>
                <slide :key="slide">
                    <div class='carousel__item'>
                        <div class="card rounded-3 bg-pink border-0 h-100">
                            <div class="card-body text-center py-5">
                                <div class="img-wrap mb-4 mx-auto">
                                    <img class="img-fluid" :src="require('../assets/images/user-mangment-icon.png')" alt="" srcset="">
                                </div>
                                <h5 class="card-title fw-bold">Inventory Forecast</h5>
                                <p class="card-text">Get forecasts of product availabilities based on confirmed sales orders, purchase orders,s or manufacturing orders.
                                </p>
                            </div>
                        </div>
                    </div>
                </slide>
               
                
                <template #addons="{ slidesCount }">
                <navigation />
                <pagination v-if="slidesCount > 4"/>
                </template>
            </carousel>
            <!-- <div class="row">
                <div class="col-lg-3 col-md-4">
                    <div class="card rounded-3 bg-primary-light border-0 h-100">
                        <div class="card-body text-center py-5">
                            <div class="img-wrap mb-4 mx-auto">
                                <img class="img-fluid" :src="require('../assets/images/sale-managment-icon.png')" alt="" srcset="">
                            </div>
                            <h6 class="card-title">Sale Order Management</h6>
                            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4">
                    <div class="card rounded-3 bg-secondary-light border-0 h-100">
                        <div class="card-body text-center py-5">
                            <div class="img-wrap mb-4 mx-auto">
                                <img class="img-fluid" :src="require('../assets/images/account-managment-icon.png')" alt="" srcset="">
                            </div>
                            <h6 class="card-title">Account Management</h6>
                            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4">
                    <div class="card rounded-3 bg-pink-light border-0 h-100">
                        <div class="card-body text-center py-5">
                            <div class="img-wrap mb-4 mx-auto">
                                <img class="img-fluid" :src="require('../assets/images/manage-inventory-icon.png')" alt="" srcset="">
                            </div>
                            <h6 class="card-title">Manage inventory</h6>
                            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-4">
                    <div class="card rounded-3 bg-pink border-0 h-100">
                        <div class="card-body text-center py-5">
                            <div class="img-wrap mb-4 mx-auto">
                                <img class="img-fluid" :src="require('../assets/images/user-mangment-icon.png')" alt="" srcset="">
                            </div>
                            <h6 class="card-title">User Management</h6>
                            <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                            </p>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </section>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

export default {
  name: 'App',
  components: {
    Carousel,
    Slide,
    // Navigation,
    Pagination
  },
  data() {
		return {
			// carousel settings
			settings: {
				itemsToShow: 1,
				snapAlign: "center",
                // wrapAround: "true",
                // autoplay: 2500,
                // transition: 1000
			},
			breakpoints: {
                450: {
					itemsToShow: 1.5,
					snapAlign: "center"
				},
				576: {
					itemsToShow: 2,
                    snapAlign: "start"
				},
				992: {
					itemsToShow: 4,
                    snapAlign: "start"
				}
			}
		};
	}
};
</script>

<style>

</style>