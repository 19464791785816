<template>
  <nav id="topnavbar" :class="{sticky:active}" class="navbar navbar-expand-lg navbar-light">
      <div class="container">
        <router-link class="navbar-brand" to="/">
        <img class="nav-img" :src="require('../assets/images/evendy-logo-dark.png')" alt="">
        <img class="nav-img-fixed" :src="require('../assets/images/evendy-logo.png')" alt="">
        </router-link>

        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#topNavContent" aria-controls="topNavContent">
          <span class="navbar-toggler-icon"><fa :icon="['fas', 'bars']"/></span>
        </button>
        <div class="offcanvas offcanvas-start" id="topNavContent" aria-labelledby="offcanvasExampleLabel">
          <div class="nav-logo bg-primary d-lg-none">
            <a class="text-white" href="#"><h2 class="mb-0">Vendi</h2></a>
            <a href="javascript:void(0)" class="close-icon d-lg-none text-white" data-bs-toggle="offcanvas" onclick="closeNav()"><i class="fas fa-times"></i></a>
          </div>
          <ul class="navbar-nav me-lg-auto mb-4 mb-lg-0">
            <li class="nav-item">
              <router-link class="nav-link" aria-current="page" to="/" v-scroll-to="'#hero-sec'">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/" v-scroll-to="'#feature-sec'" >Features</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/" v-scroll-to="'#report-mngmnt-sec'">Reports</router-link>
            </li>
            <!-- <li class="nav-item">
              <router-link class="nav-link" to="/" v-scroll-to="'#usp-sec'">Pricing</router-link>
            </li> -->
            <li class="nav-item">
              <router-link class="nav-link" to="/contact">Contact Us</router-link>
            </li>
          </ul>
          <!-- <a href="#" class="btn ms-lg-3 px-5 py-2 fw-500 btn-sm rounded-pill border border-primary text-uppercase text-primary" type="button">Login</a> -->
        </div>
      </div>
    </nav>
  
</template>

<script>
export default {
  el: "#topnavbar",
  data(){
    return {
      active: false
    }
      
  },
  methods: {
    toggleNavClass(){
        if(this.active == false){
          return 'nav'
        } else {
          return 'sticky-nav'
        }

    }
  },
  mounted(){
  window.document.onscroll = () => {
      let navBar = document.getElementById('topnavbar');
      if(window.scrollY > navBar.offsetTop){
        this.active = true;
        } else {
        this.active = false;
      }
    }
  }
}
</script>

<style>

</style>