<template>
    <section id="client-sec" class="client-sec">
        <div class="container">
            <div class="row flex-lg-row-reverse align-items-center">
                <div class="col-lg-6 mb-lg-0 mb-5">
                    <div class="img-wrap client-img">
                        <img class="img-fluid" :src="require('../assets/images/client-img.png')" alt="" srcset="">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="mb-3 d-flex flex-column">
                        <div class="img-wrap title-logo me-1 justify-content-start mb-1">
                            <img class="img-fluid" :src="require('../assets/images/evendy-logo-dark.png')" alt="" srcset="">
                        </div>
                        <h2 class="text-primary fw-bold">Client</h2>
                    </div>
                    <p class="mb-3">Ever since the establishment of United Industries Limited in 1962, they are proudly housing the most beloved consumer goods for every generation.
                    </p>
                    <p class="mb-3">With a distribution network spread across the country, UIL strongly emphasizes on Research & Development. International standards are maintained in all products by using the latest modern techniques of purification and processing through ultra-high-tech equipment.
                    </p>
                    <p class="mb-3">Now, UIL is using Vendi to manage their warehouse and sale transmission. <br>
                    UIL is a happy and our progressive client.
                    </p>
                    <p class="mb-3">They believe in the goodness of nature which is why they strive to bring the purest, most natural products to the market. Their offerings are chemical free, all sourced from pristine farms and fields across the world.
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>

</style>