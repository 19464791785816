<template>
    <section id="hero-sec" class="hero-sec privacy mb-0">
        <div class="container">
            <div class="row flex-lg-row-reverse align-items-center my-5">
                <div class="col-12">
                    <h1 class="mb-3 text-center text-white fw-bold">Privacy Policy</h1>
                    <p class="mb-5 text-center text-white w-lg-90 mx-lg-auto">
                        This Privacy Policy outlines how we collect, use, and protect your personal information when you
                        interact with our services.
                    </p>
                </div>
            </div>
        </div>
    </section>
    <section class="v-contact privacy my-5 p-0">
        <div class="container">
            <div class="row align-items-center mt-5 justify-content-center">
                <div class="col-md-12">
                    <p>At <span class="fw-bold">Vendi</span>, we are committed to protecting the privacy of our website visitors. This Privacy Policy outlines how we collect, use, and protect your personal information when you interact with our website. By using our website, you consent to the practices described in this policy. Please
                        read this policy carefully to understand how we handle your information.</p>
                    <ol>
                        <li>
                            <h5>Information We Collect:</h5>
                            <p>We may collect various types of information when you use our services, including:
                            <ol type="a">
                                <li><span class="fw-600"> Personal Information:</span> When you voluntarily provide it to us, we may collect personal information such as your name, email address, contact details, or any other information that you provide through our website's forms or communication channels.

                                </li>
                                <li>
                                    <span class="fw-600"> Usage Information: </span>We collect information about how you interact with our services, such
                                    as your IP address, device information, browser type, and operating system.
                                </li>
                                <li><span class="fw-600"> Cookies and Similar Technologies: </span>We may use cookies and similar technologies to enhance
                                    your experience on our website. Cookies are small text files stored on your device that enable us to recognize your preferences and track your activities on our site.These technologies allow us to collect information about
                                    your browsing activities.

                                </li>
                            </ol>
                            </p>
                        </li>
                        <li>
                            <h5>
                                Use of Information:
                            </h5>
                            <p>We use the collected information for the following purposes:</p>
                            <ol type="a">
                                <li > <span class="fw-600"> Providing and Improving Services: </span>We use your information to deliver and enhance the
                                    services we offer, personalize your experience, and respond to your inquiries.</li>
                                <li><span class="fw-600">  Communication: </span>We may use your email address or contact information to send you
                                    relevant updates, newsletters, or promotional materials. You can opt-out of receiving
                                    such communications at any time.</li>
                                <li><span class="fw-600"> Analytics and Research:</span> We analyze user behavior and preferences to improve our
                                    services, troubleshoot issues, and conduct research to enhance user experience.</li>
                            </ol>
                        </li>

                        <li>
                            <h5>Data Sharing:</h5>
                            <p>We do not sell, trade, or rent your personal information to third parties for their marketing purposes. However, we may share your information with trusted third-party service providers who assist us in operating our website or providing services to you. These service providers are contractually obligated to maintain the confidentiality and security of your information.</p>
                        </li>
                        <li>
                            <h5>Data Security:</h5>
                            <p>We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, loss, misuse, or alteration. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.

</p>
                        </li>
                        <li>
                            <h5>Data Retention:</h5>
                            <p>We retain your personal information only for as long as necessary to fulfill the purposes
                                outlined in this Privacy Policy, unless a longer retention period is required or permitted
                            by law.</p>
                    </li>
                    <li>
                        <h5>Third-Party Links:</h5>
                        <p>Our services may contain links to third-party websites or services. We are not responsible for the privacy practices or content of those third parties. We encourage you to read the privacy policies of any third-party websites you visit.</p>
                    </li>
                    <li>
                        <h5>
                            Changes to the Privacy Policy:
                        </h5>
                        <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be reflected on this page, and the updated version will be effective immediately upon posting. We encourage you to review this Privacy Policy periodically for any updates.</p>
                    </li>
                    <li>
                        <h5>Contact Us:</h5>
                        <p>If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your personal information, please contact us.</p>
                        <div class="social-container mt-1">
            <div class="contact-ifo">
              
              <div class="mt-1 app-info">
                <div class="d-flex">
                  <h5 class="me-3">
                    <fa :icon="['fas', 'map-marker-alt']" />
                  </h5>
                  <p>
                   P-6 Mall 2 , 2nd Floor, Kohinoor 1, Faisalabad, Pakistan
                  </p>
                </div>
                <div class="d-flex">
                  <h5 class="me-3"><fa :icon="['fas', 'phone-alt']" /></h5>
                  <p>+923000880001</p>
                </div>
                <div class="d-flex">
                  <h5 class="me-3"><fa :icon="['fas', 'envelope']" /></h5>
                  <p>info@vendi.pk</p>
                </div>
              </div>
              <ul class="social-icons mt-0">
                <li>
                  <a href="#"><fa class="i" :icon="['fab', 'instagram']" /></a>
                </li>
                <li>
                  <a href="#"><fa class="i" :icon="['fab', 'twitter']" /></a>
                </li>
                <li>
                  <a href="https://www.facebook.com/Vendipk-107412725011866"><fa class="i" :icon="['fab', 'facebook-f']" /></a>
                </li>
              </ul>
              <!-- <button class="btn btn-primary hvr-icon-wobble-horizontal mt-3">
                    Find Location <fa class="hvr-icon" :icon="['fas', 'arrow-right']"
                      />
                  </button> -->
            </div>
          </div>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</section></template>


<script>


</script>

<style lang="scss" scoped>  
.privacy {
    min-height: auto !important;
    line-height: 1.8;
}
ol li{
    margin-bottom: 10px;
}

.fw-600{
    font-weight: 600;
}
.app-info {
    h5 {
      color: #188f91;
    }
   
  }
  .social-icons {
    $timing: 265ms;
    $iconColor: #00b5f5;
    $accent: #002a8f;
    $bluefade: #0043e0;
    $gradient: #00b5f5;

    @mixin transformScale($size: 1) {
      transform: scale($size);
      -ms-transform: scale($size);
      -webkit-transform: scale($size);
    }

    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      margin: 0.15em;
      position: relative;
      font-size: 1.2em;
    }

    .i {
      color: #fff;
      position: absolute;
      top: 13px;
      left: 13px;
      transition: all $timing ease-out;
    }

    a {
      display: inline-block;

      &:before {
        @include transformScale();
        content: " ";
        width: 40px;
        height: 40px;
        border-radius: 100%;
        display: block;
        background: linear-gradient(to right, #a1dd9a 0%, #24b2bb 100%);
        transition: all $timing ease-out;
      }

      &:hover:before {
        transform: scale(0);
        transition: all $timing ease-in;
      }

      &:hover .i {
        @include transformScale(1.5);
        color: $iconColor;
        background: linear-gradient(to right, #a1dd9a 0%, #24b2bb 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: all $timing ease-in;
      }
    }
  }
  .contct-info,
  .app-info {
    p {
      font-size: 14px;
    }
  }
</style>