<template>
  <section id="usp-sec" class="usp-sec">
    <div class="container">
      <div class="img-wrap title-logo mb-1">
        <img
          class="img-fluid"
          :src="require('../assets/images/evendy-logo-dark.png')"
          alt=""
          srcset=""
        />
      </div>
      <h2 class="text-center mb-3 text-primary fw-bold">
        What else is included
      </h2>
      <p class="mb-5 text-center px-lg-7">
        Inside the system, you will find all sub-modules necessary to run all
        your operations.
      </p>
      <carousel :settings="settings" :breakpoints="breakpoints">
        <slide :key="slide">
          <div class="carousel__item">
            <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-5 pt-0">
                <div class="img-wrap mb-4 mx-auto">
                  <img
                    class="img-fluid"
                    :src="require('../assets/images/manpower-icon.png')"
                    alt=""
                    srcset=""
                  />
                </div>
                <h5 class="card-title mb-4 text-primary px-5">
                  Sales Routes & Planning
                </h5>
                <ul class="list-unstyled text-start mb-0">
                  <li class="d-flex align-items-start">
                    <fa :icon="['fas', 'check-circle']" class="mt-1" />
                    <a href="">
                      Vendi uses advanced Route Planning management including
                      Outlet Proximity</a
                    >
                  </li>
                  <li class="d-flex align-items-start">
                    <fa :icon="['fas', 'check-circle']" class="mt-1" />
                    <a href=""
                      >IT help you make the right decisions and increase your
                      on-time delivery metrics.</a
                    >
                  </li>

                  <li class="d-flex align-items-start">
                    <fa :icon="['fas', 'check-circle']" class="mt-1" />
                    <a href=""
                      >It’s automation without robots as it’s quick to deploy
                      and easy to learn</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </slide>
        <slide :key="slide">
          <div class="carousel__item">
            <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-5 pt-0">
                <div class="img-wrap mb-4 mx-auto bg-secondary-light">
                  <img
                    class="img-fluid"
                    :src="require('../assets/images/easy-opration-icon.png')"
                    alt=""
                    srcset=""
                  />
                </div>
                <h5 class="card-title mb-4 text-primary px-5">
                  Secondary Order Booking
                </h5>

                <ul class="list-unstyled text-start mb-0">
                  <li class="d-flex align-items-start">
                    <fa :icon="['fas', 'check-circle']" class="mt-1" />
                    <a href="">
                      Vendi enables an Android-based Order Booking application
                    </a>
                  </li>
                  <li class="d-flex align-items-start">
                    <fa :icon="['fas', 'check-circle']" class="mt-1" />
                    <a href="">
                      It facilitate order bookers to take orders from their
                      customers using Android mobile/Tablets.</a
                    >
                  </li>
                  <li class="d-flex align-items-start">
                    <fa :icon="['fas', 'check-circle']" class="mt-1" /><a href=""
                      >IT is available in online and offline versions.</a
                    >
                  </li>

                  <li class="d-flex align-items-start">
                    <fa :icon="['fas', 'check-circle']" class="mt-1" />
                    <a href="">Simple and intuitive UI</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </slide>
        <slide :key="slide">
          <div class="carousel__item">
            <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-5 pt-0">
                <div class="img-wrap mb-4 mx-auto bg-secondary-light">
                  <img
                    class="img-fluid"
                    :src="require('../assets/images/easy-opration-icon.png')"
                    alt=""
                    srcset=""
                  />
                </div>
                <h5 class="card-title mb-4 text-primary px-5">
                  Reports & Dashboards
                </h5>

                <ul class="list-unstyled text-start mb-0">
                  <li class="d-flex align-items-start">
                    <fa :icon="['fas', 'check-circle']" class="mt-1" /><a href="">
                      You are reporting while on the move.</a
                    >
                  </li>
                  <li class="d-flex align-items-start">
                    <fa :icon="['fas', 'check-circle']" class="mt-1" /><a href=""
                      >You can analyze your business analytics with Vendi's
                      powerful Reports & Dashboards.</a
                    >
                  </li>
                  <li class="d-flex align-items-start">
                    <fa :icon="['fas', 'check-circle']" class="mt-1" /><a href=""
                      >You are rendering the correct analytical data to extend
                      business strategy & future planning.
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </slide>
        <slide :key="slide">
          <div class="carousel__item">
            <div class="card rounded-3 border-0">
              <div class="card-body text-center pt-md-0 p-md-5 pt-0">
                <div class="img-wrap mb-4 mx-auto bg-pink-light">
                  <img
                    class="img-fluid"
                    :src="require('../assets/images/opration-icon.png')"
                    alt=""
                    srcset=""
                  />
                </div>
                <h5 class="card-title mb-4 text-primary px-5">
                  Schemes & Discounts
                </h5>

                <ul class="list-unstyled text-start mb-0">
                  <li class="d-flex align-items-start">
                    <fa :icon="['fas', 'check-circle']" class="mt-1" /><a href=""
                      >Centrally manage and update promotions and trade offers
                      to enhance controls & profit.
                    </a>
                  </li>
                  <li class="d-flex align-items-start">
                    <fa :icon="['fas', 'check-circle']" class="mt-1" /><a href=""
                      >Update your existing promotions / Trade
                    </a>
                  </li>

                  <li class="d-flex align-items-start">
                    <fa :icon="['fas', 'check-circle']" class="mt-1" /><a href=""
                      >Offers either for single or multiple channels with
                      Vendi</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </slide>

        <template #addons="{ slidesCount }">
          <navigation />
          <pagination v-if="slidesCount > 3" />
        </template>
      </carousel>
    </div>
  </section>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";

export default {
  name: "App",
  components: {
    Carousel,
    Slide,
    // Navigation,
    Pagination,
  },
  data() {
    return {
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        wrapAround: "true",
        // autoplay: 2500,
        // transition: 1000
      },
      breakpoints: {
        450: {
          itemsToShow: 1.5,
          snapAlign: "center",
        },
        576: {
          itemsToShow: 2,
          snapAlign: "start",
        },
        992: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
};
</script>

<style>
</style>